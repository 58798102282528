import React, {useState} from 'react';
import { NavLink } from "react-router-dom";
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { Alert } from "@mui/material";
import Api from "../../utils/Api";
import { useDispatch, } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import { userRegister } from '../../store/actions';

function Register(props) {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [userData, setUserData] = useState({});
    const [validated, setValidated] = useState(false);
    const [isProcessing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    let [errors, setErrors] = useState({});
    
    const validate = () => {
        if (userData.email && !/^[a-zA-Z0-9._]+(?:\+[a-zA-Z0-9._]+)?@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(userData.email) ) {
            errors['email'] = `${t('emailAddress')} ${t('isNotValid')}`;
        }
        
        if( userData.password && /\s/g.test(userData.password) )
            errors['password'] = `${t('password')} ${t('isNotValid')}`;
        else if( userData.password && userData.password.length < 6 )
            errors['password'] = t('passwordMust6');

        if( userData.password && userData.password !== userData.confirm_password ){
            errors['confirm_password'] = t('passwordNotMatch');   
        }
    }

    const handleSubmit = async(e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();

        errors = {};
        let valid = false;

        setError(false);
        setSuccess(false);

        validate();
        valid = form.checkValidity() && Object.keys(errors).length === 0;

        if( valid ){
            setProcessing(true);
            Api.post('register',userData).then( res => {
                setProcessing(false);
                if( res.success ){
                    dispatch(userRegister(res?.data));
                    setSuccess("Account created successfully, Please check your email to complete the signup process.");
                    // userData['account_completion_url'] = `${window.location.origin.toString()}/complete-signup/${res.data?.id}/${res.data?.public_id}`;

                    // Api.hubspotSubmit(userData).then(_res => {
                    //     setProcessing(false);
                    //     if( _res.success )
                    //         setSuccess("Account created successfully, Please check your email to complete the signup process.");
                    //     else  
                    //         setError(_res.error);
                    // });
                    
                } else {
                    setProcessing(false);
                    setError(res.error);
                }
            });
        }

        setErrors(errors);    
        setValidated(true);
    }
    
    const saveFieldData = (name,value) =>{
        userData[name] = value.trim();
        if( userData[name] === '' )
            delete userData[name];
        setUserData(userData);
    }

    return (

        <div className="m-0">

            <h2 className="heading m-0 mb-4 text-center">{t("signupDesc1")}</h2>
            <p className="m-0 mb-5 text-center">{t("signupDesc2")}</p>

            <div className="registerBox">
                {error && <Alert severity="error" className="mb-3">{error} !</Alert>}
                {success && <Alert severity="success" className="mb-3">{success} !</Alert>}

                {!success && <div>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="p-4">
                    <div className="m-2">
                        <Row className="mb-3">
                            <Form.Group as={Col} xs>
                                <Form.Label>{t("firstName")}</Form.Label>
                                <Form.Control required type="text" onChange={e => saveFieldData('firstname',e.target.value)} />
                                <Form.Control.Feedback type="invalid">{`${t("firstName")} ${t("isRequired")}.`}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs>
                                <Form.Label>{t("lastName")}</Form.Label>
                                <Form.Control required type="text" onChange={e => saveFieldData('lastname',e.target.value)}/>
                                <Form.Control.Feedback type="invalid">{`${t("lastName")} ${t("isRequired")}.`}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                            
                        <Form.Group className="mb-3">
                            <Form.Label>{t("emailAddress")}</Form.Label>
                            <Form.Control required type="email" onChange={e => saveFieldData('email',e.target.value)} isInvalid={!!errors.email}/>
                            <Form.Control.Feedback type="invalid">{errors.email || `${t("emailAddress")} ${t("isRequired")}.`}</Form.Control.Feedback>
                        </Form.Group>
                               
                        <Form.Group className="mb-3">
                            <Form.Label>{t("companyName")}</Form.Label>
                            <Form.Control type="text" onChange={e => saveFieldData('company',e.target.value)} />
                        </Form.Group>
                        
                        <Row className="mb-4">
                            <Form.Group as={Col} xs>
                                <Form.Label>{t("password")}</Form.Label>
                                <Form.Control required type="password" onChange={e => saveFieldData('password',e.target.value)} isInvalid={!!errors.password}/>
                                <Form.Control.Feedback type="invalid">{errors.password || `${t("password")} ${t("isRequired")}.`}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs>
                                <Form.Label>{t("confirmPassword")}</Form.Label>
                                <Form.Control required type="password" onChange={e => saveFieldData('confirm_password',e.target.value)} isInvalid={!!errors.confirm_password}/>
                                <Form.Control.Feedback type="invalid">{errors.confirm_password || `${t("confirmPassword")} ${t("isRequired")}.`}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                       
                        <div className="mt-2">
                            <Button type="submit" disabled={isProcessing}>
                                {isProcessing && <Spinner className="mx-5" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                                {!isProcessing && <span>{t("createAccount")}</span>}
                            </Button>
                        </div> 
                    </div>
                </Form>
                </div>}

                <p className="m-0 mt-5">{t("signupDesc3")}</p>

                <p className="m-0 mt-3">{t("signupDesc4")}</p>

                <p className="m-0 mt-3">
                    {t("seeOur")} <NavLink to="/privacy-policy">{t("privacyPolicy")}</NavLink>.
                </p>

            </div>
        </div>
    );
  
}

export default Register;