import Config from '../config.json';
import axios from "axios";

class Api{

    config  = {};
    headers = {};

    constructor(config) {

        this.config = config;

        this.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Max-Age': 600,
        };
    } 

    setToken = (token = null) => {
        this.config['authToken'] = token;
    }

    prepareParams = (method, path, conf = {}) =>{

        if( conf.token ){
            this.config['authToken'] = conf.token;
            delete conf.token;
        }

        if( this.config.authToken)
            this.headers['Authorization'] = 'Bearer '+this.config.authToken.trim();
            
        var params = {
            url: path.indexOf('://') !== -1 ? path : this.config.apiBaseUrl.trim()+path.trim(),
            method: method,
            headers: this.headers,
        };

        if( conf ){
            Object.keys(conf).forEach(key => {
                params[key] = conf[key];       
            });
        }
        return params;
    }

    get = async(path,conf) => {
        return await this.call('get',path, null, conf);
    }

    post = async(path,data,conf) => {
        return await this.call('post',path, data, conf);
    }

    put = async(path,data,conf) => {
        return await this.call('put',path, data, conf);
    }

    delete = async(path,conf) => {
        return await this.call('delete',path, null, conf);
    }

    call = async(method,path,data,conf) => {

        var _frmt = (conf && conf?.external) || false; 
        if( conf && conf?.external) delete conf.external;
        
        var ret = {}, params = this.prepareParams(method,path, conf);
        
        console.log("API_CALLSSS________",params)

        try{ 
            
            if( data ) 
                params['data'] = data;
            
            await axios(params).then((resp) => {
                
                if( _frmt )
                    ret = { success:true, error:false, data:resp };
                else {
                    ret = resp.data;
                    if( !ret.success && !ret.error)
                        ret['error'] = "Unable to process request";
                }

            }).catch((error) => {
                ret = { success:false, error:error.message || "Unable to process request", data:{} };
            });

        } catch (err) {
            ret = { success:false, error:"Unable to process request", data:{} };
        }
        
        return ret;
    }

    hubspotSubmit = async(data, method='post') =>{

        var ret = {}, hsApiUrl = 'https://api.hsforms.com/submissions/v3/integration/';
        
        var params = {
            url: `${hsApiUrl}submit/${this.config?.hubSpot?.portalId}/${this.config?.hubSpot?.formId}`,
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }

        try{ 

            var _fields = [];
            if( method === 'post' && data){
                Object.keys(data).forEach(key => {
                    _fields.push({ objectTypeId:"0-1", name: key, value: data[key].trim() });       
                });
                params['data'] = {fields: _fields};      
            }

            await axios(params).then((resp) => {
                console.log("hubspot", resp);
                ret = { success:true, error:false , data:resp.data };
            }).catch((error) => {
                var _error = error.message || "Unable to process request";
                if( error.response.data && error.response.data.message )
                    _error = error.response.data.message;
                ret = { success:false, error:_error , data:{} };
            });

        } catch (err) {
            ret = { success:false, error:"Unable to process request", data:{} };
        }

        return ret;
    }
}

export default new Api(Config);