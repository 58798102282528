import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function Dashboard(props) {
    const user = useSelector((state) => state.userData);
    const {t} = useTranslation();
    return (
        <div className="main-box blue p-5">  
            <p className="text-center lh-30">
              <b>{t("dashboardTitle1")}</b><br/>
              {user?.type !== 'T' && <span><b>{t("dashboardTitle2")} <br/>{t("dashboardTitle3")}</b></span>}
            </p>       
          
        </div>
    );
}

export default Dashboard;
