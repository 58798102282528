import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { Alert } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import Api from "../../utils/Api";
import { useTranslation } from 'react-i18next';

function CompleteProfile() {

    let { id, hash } = useParams();
    const {t} = useTranslation();
    const [userData, setUserData] = useState({});
    const [validated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isInvalidRequest, setInvalid] = useState(false);
    
    const [isProcessing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    let [errors, setErrors] = useState({});

    useEffect(() => {   
        if( isLoading && !isInvalidRequest){
            Api.get(`get/${id.trim()}/${hash.trim()}`).then(res=>{
                setLoading(false);
                if( !res.success )
                    setInvalid(res.error)
            });
        }
    },[]);

    const validate = () => {
        
        if( userData.password && /\s/g.test(userData.password) )
            errors['password'] = `${t('password')} ${t('isNotValid')}`;
        else if( userData.password && userData.password.length < 6 )
            errors['password'] = t('passwordMust6');

        if( userData.password && userData.password !== userData.password_confirmation ){
            errors['password_confirmation'] = t('passwordNotMatch');   
        }
    }

    const handleSubmit = async(e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();

        errors = {};
        let valid = false;
        setError(false);
        setSuccess(false);

        validate();
        valid = form.checkValidity() && Object.keys(errors).length === 0;

        if( valid ){
            setProcessing(true);
            Api.post(`updatePassword/${id.trim()}/${hash.trim()}`, userData).then(res => {
                setProcessing(false);
                if( res.success )
                    setSuccess(t("signupComplete"));
                else
                    setError(res.error);
            });
        }

        setErrors(errors);    
        setValidated(true);
    }
    
    const saveFieldData = (name,value) =>{
        userData[name] = value.trim();
        if( userData[name] === '' )
            delete userData[name];
        setUserData(userData);
    }

    return (
        <div className="m-0">

            <h2 className="heading m-0 mb-4 text-center">{t("completeSignup")}</h2>
            
            <div className="registerBox">
                {success && <Alert severity="success" className="mt-5 mb-5 text-center" style={{justifyContent: "center"}}>{success} !</Alert>}
            
                {error && <Alert severity="error" className="mb-3">{error} !</Alert>}

                {isInvalidRequest && <Alert severity="error" className="mb-3">{isInvalidRequest} !</Alert>}
                
                {isLoading && <div className="text-center"><Spinner className="mx-4" as="span" animation="border" size="sm" role="status" aria-hidden="true"/> <div>{t("verifing")} ...</div></div>}

                {!isLoading && !isInvalidRequest && !success && <div>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="p-4">
                        <Row className="mb-4">
                            <Form.Group as={Col} xs>
                                <Form.Label>{t("password")}</Form.Label>
                                <Form.Control required type="password" onChange={e => saveFieldData('password',e.target.value)} isInvalid={!!errors.password}/>
                                <Form.Control.Feedback type="invalid">{errors.password || "Password is required."}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs>
                                <Form.Label>{t("confirmPassword")}</Form.Label>
                                <Form.Control required type="password" onChange={e => saveFieldData('password_confirmation',e.target.value)} isInvalid={!!errors.password_confirmation}/>
                                <Form.Control.Feedback type="invalid">{errors.password_confirmation || "Confrim password is required."}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <div className="mt-2">
                            <Button type="submit" disabled={isProcessing}>
                                {isProcessing && <Spinner className="mx-4" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                                {!isProcessing && <span>{t("complete")}</span>}
                            </Button>
                        </div> 
                    </Form>

                    <p className="m-0 mt-4">{t("completeSignupDesc")}</p>
                    <p className="m-0 mt-3">
                        {t("seeOur")} <NavLink to="/privacy-policy">{t("privacyPolicy")}</NavLink>.
                    </p>
                </div>}

            </div>
        </div>
    );
}

export default CompleteProfile;
