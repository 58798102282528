import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Row, Col, Form, Spinner } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton, Snackbar,Slide as SlideTransition, Alert } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Api from "../../utils/Api";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation,} from 'react-i18next';

let authToken = null;

function MemberForm({id, data, action, button, popupClose, setNotification, callback}){
    
    const {t} = useTranslation();
    if( action === 'add' )
        data['permissions'] = ['reports'];

    var [userData, setUserData] = useState(data);
    var [validated, setValidated] = useState(false);
    var [allowSubmit, setAllowSubmit] = useState(action !== 'update');
    var [isProcessing, setProcessing] = useState(false);
    var [error, setError] = useState(false);
    var [errors, setErrors] = useState({});
    var permissions = { "reports": t("viewReports"), "robots": t("viewRobots"), "billing": t("viewBillings") };

    const validate = () => {
       
        if (userData.email && !/^[a-zA-Z0-9._]+(?:\+[a-zA-Z0-9._]+)?@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(userData.email) ) {
            errors['email'] = `${t("emailAddress")} ${t("isNotValid")}`;
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        errors = {};
        setError(false);
    
        if( action !== 'delete')
            validate();

        var valid = action === 'delete' || (form.checkValidity() && Object.keys(errors).length === 0);

        if( valid ){
            
            setProcessing(true);
            // Api.setToken(authToken);
            Api.call(
                action === 'delete' ? 'get' : 'post',
                'team/'+action+(['update','delete'].includes(action) ? '/'+id : ''),
                {...userData , type:"M"}
            ).then(res=>{
                console.log("____RESSSS__", res)

                if( res.success ){
                    if( action === 'add'){
                        if( callback ) callback(action, userData, res.data || {});
                        var frmData = {
                            recipient: res?.data?.email,
                            link: `${window.location.origin.toString()}/complete-signup/${res.data?.id}/${res.data?.public_id}`,
                        }
                        console.log("frmData", frmData)

                        Api.post('sendEmail', frmData).then(_res => {
                            console.log("____________sendEmail______",_res);
                            setProcessing(false);
                            if( _res.success ){
                                popupClose();
                                setNotification("Team member invited successfully");
                            } else
                                setError("Team member created, but unable to send invite");
                        });
                    } else {
                        setProcessing(false);
                        popupClose();
                        setNotification(action === 'delete' ? "Team member deleted successfully" : "Team member updated successfully");
                        if( callback ) callback(action, userData, res.data || {});
                    }

                } else {
                    setProcessing(false);
                    setError(res.error);
                }
            });

        } else
            setError("Vadation error");

        setErrors(errors);    
        setValidated(true);
    }

    const saveCheckboxData = (name,value,checked) =>{
        value = value.trim();
        
        if( !userData[name] )
            userData[name] = [];

        if( checked )
            userData[name].push(value);
        else
            userData[name] = userData[name].filter((prms)=>{ return prms !== value });

        if( !userData[name].length )
            delete userData[name];

        setUserData(userData);
        setAllowSubmit(true);
    }

    const saveFieldData = (name,value) =>{
        userData[name] = value.trim();

        if( userData[name] === '' )
            delete userData[name];

        setUserData(userData);
        setAllowSubmit(true);
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

            <Modal.Body>            
                <div className="mt-0 m-2">
                    {error && <Alert severity="error" className="mb-3">{error} !</Alert>}
                    {action !== 'delete' && <>
                    <Row className="mb-3">
                        <Form.Group as={Col} xs>
                            <Form.Label>{t("firstName")}</Form.Label>
                            <Form.Control required type="text" onChange={e => saveFieldData('firstname',e.target.value)} defaultValue={userData?.firstname || ""} />
                            <Form.Control.Feedback type="invalid">{`${t("firstName")} ${t("isRequired")}.`}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xs>
                            <Form.Label>{t("lastName")}</Form.Label>
                            <Form.Control required type="text" onChange={e => saveFieldData('lastname',e.target.value)} defaultValue={data?.lastname || ""}/>
                            <Form.Control.Feedback type="invalid">{`${t("lastName")} ${t("isRequired")}.`}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("emailAddress")}</Form.Label>
                        <Form.Control required disabled={action == "update" ? true : false} type="email" onChange={e => saveFieldData('email',e.target.value)} isInvalid={!!errors.email} defaultValue={data?.email || ""}/>
                        <Form.Control.Feedback type="invalid">{errors.email || `${t("emailAddress")} ${t("isRequired")}.` }</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("permissions")}</Form.Label>
                        <Row>
                            {Object.keys(permissions).map((key) => (
                                <Col key={`permission-${key}`} className="col-4">
                                      <Form.Check
                                        inline
                                        value={key}
                                        defaultChecked={data?.permissions && data.permissions.includes(key)}
                                        onChange={e => saveCheckboxData('permissions',e.target.value,e.target.checked)}
                                        label={permissions[key]}
                                        name="permissions"
                                        type="checkbox"
                                        id={`permission-${key}`}
                                      />
                                </Col>
                            ))}
                        </Row>
                    </Form.Group>
                    </>}
                    {action === 'delete' && <p className="m-0">Are you sure to delete this member?</p>}
                </div>
            </Modal.Body>
  
            <Modal.Footer>
                <Button variant="primary" type="submit" disabled={isProcessing || !allowSubmit}>
                    {isProcessing && <Spinner className="mx-3" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                    {!isProcessing && <span>{button}</span>} 
                </Button>
            </Modal.Footer>
        </Form>
    ); 
}

function NewMemberPopup({callback, setNotification}){

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {t} = useTranslation();

    return (
        <>
            <Button variant="primary" onClick={handleShow} className="heading-topright">{t("addNew")}</Button>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("newMember")}</Modal.Title>
                </Modal.Header>
                <MemberForm id={0} action="add" data={{}} button= {t("invite")} popupClose={handleClose} setNotification={setNotification} callback={callback} />
            </Modal>
        </>
    );
}

function EditMemberPopup({data, action, show, handleClose, callback, setNotification}){
    const {t} = useTranslation();

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{action} {t("member")}: {data?.firstname || null}</Modal.Title>
                </Modal.Header>
                <MemberForm id={data?.id || 0} action={action} data={data || {}} button={action}  setNotification={setNotification} callback={callback} popupClose={handleClose}/>
            </Modal>
        </>
    );
}

function ShowNotification({message,open,handleClose,type,time}){
    const {t} = useTranslation();

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open || false}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            autoHideDuration={time || 10000}
        >
            <Alert onClose={handleClose} severity={type || "success"} sx={{ width: '100%' }}>{message || null}</Alert>
        </Snackbar>
    );
}

function MemberList({items, setNotification, callback}){
    const {t} = useTranslation();      
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showPopup, setShowPopup] = useState(false);
    const [formAction, setFormAction] = useState('update');

    const [formData, setFormData] = useState();
    
    const handlePopupClose = () => setShowPopup(false);
    const handlePage = (event, newPage) => setPage(newPage);

    const handleRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const memberActions = (action,data) => {
        setFormAction(action);
        setFormData(data);
        setShowPopup(true);
    }

    const handleCallback = (action, data, resp) =>{
        if( action !== 'update') setPage(0);
        callback(action, data, resp);
    }

    return (
        <>
          <EditMemberPopup data={formData} action={formAction} show={showPopup} handleClose={handlePopupClose} callback={handleCallback} setNotification={setNotification} />
          <TableContainer>
              <Table>
                <TableHead className="thead">
                  <TableRow>
                    <TableCell className="th" component="th">{t("name")}</TableCell>
                    <TableCell className="th" component="th">{t("email")}</TableCell>
                    <TableCell className="th" component="th">{t("status")}</TableCell>
                    <TableCell className="th" component="th" align="right"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tbody">
                  {!items.length && <TableRow><TableCell className="td" colspan="4" scope="row" style={{textAlign:"center"}}><br/>{t("noMembers")}<br/><br/></TableCell></TableRow>}
                  {items.length && items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, i) => (
                    <TableRow key={i} hover role="checkbox" tabIndex={-1}>
                      <TableCell className="td" scope="row">{item.firstname} {item.lastname}</TableCell>
                      <TableCell className="td">{item.email}</TableCell>
                      <TableCell className="td" style={{textTransform: 'capitalize'}}>{item.status || "pending"}</TableCell>
                      <TableCell className="td" align="right"> 
                        <IconButton aria-label="edit" color="primary" size="small" onClick={()=>memberActions('update',item)}>
                          <EditIcon/>
                        </IconButton>
                        <IconButton aria-label="delete" color="error" size="small" onClick={()=>memberActions('delete',item)}>
                          <DeleteIcon/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="pagination"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={items.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePage}
              onRowsPerPageChange={handleRowsPerPage}
            />
        </>
    )

}

function Team(props) {

    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData);
    const {t} = useTranslation();
    const [notification, setNotification] = useState(null);
    const hideNotification = () => setNotification(null);
    const [delIds, setDelIds] = useState([]);
    var [isLoaded, setLoaded] = useState(false);
    var [members, setMembers] = useState([]);

   

    const getMembers = useCallback(()=>{
        Api.get('team/all').then(res=>{
            if( res.success ){
                setMembers(res.data);
            }
        });
    },[userData?.id]);

    let ele = document.querySelector('.MuiTablePagination-selectLabel')
    useEffect(() => {
        if(ele){
            ele.innerHTML = t('rowsPerPage')
        }
        if( !isLoaded ){
            authToken = userData?.token;
            getMembers();  
            setLoaded(true);      
        }
    },[userData?.token, isLoaded, setLoaded, getMembers , ele]);

    const handleCallback = (action,data,resp) =>{
        var i = 0;
        if( action === 'delete' && data.id){
            delIds.push(data.id);
            setDelIds(delIds);
            var newData = [];
            for (i=0; i < members.length; i++) {
                if( members[i].id !== data.id)
                    newData.push(members[i]); 
            }
            members = newData;
        }

        if( action === 'update' && data.id ){
            for (i=0; i < members.length; i++) {
                if( members[i].id === data.id){
                    members[i] = data;
                }
            }
        }

        if( action === 'add' )
            getMembers();
        else
            setMembers(members);
    } 

    return(
        <div>  
            <h2>{t("team")}</h2>
            <ShowNotification open={notification ? true : false} message={notification} handleClose={hideNotification}/>
            <NewMemberPopup setNotification={setNotification} callback={handleCallback}/>
            <MemberList items={members} setNotification={setNotification} callback={handleCallback}/>
        </div>
    );
}

export default Team;