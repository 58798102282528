import React from 'react';
import { useTranslation } from 'react-i18next';

function Support() {
  const {t} = useTranslation();

  return (
    <div>  
      <h2>{t("support")}</h2>
      
    </div>
  );
}

export default Support;
