import React, {useState} from 'react';
import { Row, Col, Form, FloatingLabel, Button, Spinner } from 'react-bootstrap';
import { Alert } from "@mui/material";
import Api from "../../utils/Api";
import { useTranslation } from 'react-i18next';


function Forgetpassword() {

    const {t} = useTranslation();
    const [userData, setUserData] = useState({});
    const [validated, setValidated] = useState(false);
    const [isProcessing, setProcessing] = useState(false);
    const [formStep, setFormStep] = useState(1);
    
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    let [errors, setErrors] = useState({});
    
    const validate = () => {
        
        if( formStep === 1 && userData.email && !/^[a-zA-Z0-9._]+(?:\+[a-zA-Z0-9._]+)?@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(userData.email) ){
            errors['email'] = `${t("emailAddress")} ${t("isNotValid")}`;
        }

        if( formStep === 2 ){   
            if( userData.password && /\s/g.test(userData.password) )
                errors['password'] = `${t('password')} ${t('isNotValid')}`;
            else if( userData.password && userData.password.length < 6 )
                errors['password'] = t("passwordMust6");

            if( userData.password && userData.password !== userData.confirm_password ){
                errors['confirm_password'] = t("passwordNotMatch");   
            }
        }
    }
    
    const handleSubmit = async(e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();
        
        errors = {};
        let valid = false;

        setError(false);
        setSuccess(false);

        validate();
        valid = form.checkValidity() && Object.keys(errors).length === 0;

        if( valid ){
            setProcessing(true);
            
            if( formStep === 1 ){
                Api.post('resetPassword',{ email: userData.email }).then(res => {
                    setProcessing(false);
                    if( res.success ){
                        setSuccess(t('checkEmailOTP'));
                        setFormStep(2);
                        setValidated(false);     
                    } else
                        setError(res.error);
                       // setError("We couldn't find an account associated with "+userEmail+". Please try with an alternate email");
                });
            }

            if( formStep === 2 ){
                Api.post('verifyOTP',{ 
                    email: userData?.email, 
                    otp: userData?.otp, 
                    password: userData?.password,
                }).then(res => {
                    setProcessing(false);
                    if( res.success ){
                        setSuccess(t('passwordChanged'));
                        setFormStep(1);
                        setValidated(false);     
                    } else
                        setError(res.error);
                       // setError("We couldn't find an account associated with "+userEmail+". Please try with an alternate email");
                });
            }
        }
        
        setErrors(errors);    
        setValidated(true);
    }


    const saveFieldData = (name,value) =>{
        userData[name] = value.trim();
        if( userData[name] === '' )
            delete userData[name];
        setUserData(userData);
    }

    return (
        <div className="m-0 loginBox">

            <h2 className="heading m-0 mb-4">{t("resetPassword")}</h2>

            {error && <Alert severity="error" className="mb-3">{error} !</Alert>}
            {success && <Alert severity="success" className="mb-3">{success} !</Alert>}
            
            {formStep === 1 && <p className="m-0 mb-3">{t("resetPasswordDesc")}.</p>}

            <Row className="justify-content-md-center">
                <Col md="5">
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="nobox">
                        
                        {formStep === 1 &&<FloatingLabel label={t("emailAddress")} className="mb-3">
                            <Form.Control type="email" required placeholder={t("emailAddress")} className="formField" onChange={e => saveFieldData('email',e.target.value)} />
                            </FloatingLabel>}
                        {formStep === 2 && <Row className="mb-3">
                            <Form.Group as={Col} xs>
                                <FloatingLabel label="Enter OTP">
                                <Form.Control required type="otp" className="formField" onChange={e => saveFieldData('otp',e.target.value)} isInvalid={!!errors.otp}/>
                                 <Form.Control.Feedback type="invalid">{errors.otp || `${t('otp')} ${"isRequired"}.`}</Form.Control.Feedback>
                                </FloatingLabel>
                               
                            </Form.Group>
                            <Form.Group as={Col} xs></Form.Group>
                        </Row>}
                        {formStep === 2 && <Row className="mb-4">
                            <Form.Group as={Col} xs>
                                <FloatingLabel label="New Password">
                                <Form.Control required type="password" className="formField" onChange={e => saveFieldData('password',e.target.value)} isInvalid={!!errors.password}/>
                                <Form.Control.Feedback type="invalid">{errors.password || `${t('password')} ${t('isRequired')}}.` }</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} xs>
                                <FloatingLabel label="Confirm Password">
                                <Form.Control required type="password"  className="formField" onChange={e => saveFieldData('confirm_password',e.target.value)} isInvalid={!!errors.confirm_password}/>
                                <Form.Control.Feedback type="invalid">{errors.confirm_password || `${t('confirmPassword')} ${t('isRequired')}}.`}</Form.Control.Feedback>
                                </FloatingLabel>
                                
                            </Form.Group>
                        </Row>}
                        <div className="mt-4">
                            <Button type="submit" disabled={isProcessing}>
                                {isProcessing && <Spinner className="mx-3" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                                {!isProcessing && formStep === 1 && <span>{t("reset")}</span>} 
                                {!isProcessing && formStep === 2 && <span>{t("savePassword")}</span>} 
                            </Button>
                        </div>    
                    </Form>

                </Col>
                <Col md="7">
                
                </Col>
            </Row>
        </div>
    );
}

export default Forgetpassword;
