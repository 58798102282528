import React from 'react';
import { useTranslation } from 'react-i18next';

function Billing() {
  const {t} = useTranslation();
  return (
    <div>  
      <h2>{t("billing")}</h2>
      
    </div>
  );
}

export default Billing;
