import React from 'react';
import ReactApexChart from "react-apexcharts";


class PieChart extends React.Component {

    constructor(props) {
        super(props);

        var data = props.data || {};
        var series = {};

        Object.keys(data).forEach(key => {
            var _total = 0, _itms = data[key] || {};
            
            Object.keys(_itms).forEach(_date => {
                if( _itms[_date] )
                    _total += _itms[_date];
            });

            if( _total ) 
                series[key] = _total;
        });

        this.state = {
            series: Object.values(series),
            options: {
                chart: { height: 380, type: 'pie' },
                labels: Object.keys(series),
            },  
        };
    }

    render() {
        return (
            <ReactApexChart options={this.state.options} series={this.state.series} type="pie" width={380} />
        );
    }
}

export default PieChart;