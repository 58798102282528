import React from 'react';
import { useTranslation } from 'react-i18next';

function Privacypolicy() {
    const {t} = useTranslation();
    return (
        <div className="m-0">
            <h2 className="heading m-0 mb-4">{t("privacyPolicy")}</h2>
            <p className="m-0 mb-3">{t("privacyContent")} ....</p>
            <p className="m-0 mb-3">{t("privacyContent")} ....</p>
            <p className="m-0 mb-3">{t("privacyContent")} ....</p>
            <p className="m-0 mb-3">{t("privacyContent")} ....</p>
        </div>
    );
}

export default Privacypolicy;
