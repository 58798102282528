import React from 'react';
import { useTranslation, } from 'react-i18next';


function Footer(){
    const {t} = useTranslation();
    return (
        <footer className="site-footer">
           { `© ${(new Date().getFullYear())} – ${t("copyright1")} – ${t("copyright2")}` }
        </footer>
    ); 
}

export default Footer;
