import Api from "../utils/Api";

const setUserSession =(user) =>{
    sessionStorage.setItem('user', user ? JSON.stringify(user) : null);
}

const getUserSession = () => {
    return JSON.parse(sessionStorage.getItem('user'));
}

export default (state = {}, action) => {
  
  switch (action.type) {

    case "login":

      const postData = action.payload.data;

      if( !postData?.permissions )
        postData['permissions'] = [];
      Api.setToken(action.payload.token)

      setUserSession({...postData , token : action.payload.token});
      state = {
        ...state,
        isLogged: true,
        userData: {...postData , token: action.payload.token},
        token: action.payload.token
      }
      break;

    case "register":
      let udata = action.payload.data;
      Api.setToken(action.payload.token)

      if( udata?.email !== '' ){
          setUserSession( {...udata, token: action.payload.token});
          state = {
            ...state,
            isLogged: true,
            userData: {...udata, token: action.payload.token},
          }
      }
      break;
      
    case "verify":

      const user = getUserSession();
      if(user?.email && user?.token){
        Api.setToken(user.token)
      }

      if( user?.email && user?.token ){
          state = {
            ...state,
            isLogged: true,
            userData: user,
          }
      }
      break;

    case "logout":
      setUserSession(null);
      state = {
        ...state,
        isLogged: false,
        userData: {},
      }
      break;

    case "setThemeMode":
      localStorage.setItem('themeMode',action.payload);
      state = {
        ...state,
        themeMode: action.payload || "dark",
      }
      break;
  };

  return state;
};