export const userLogin = (payload) => {
  return {
    type: "login",
    payload
  }
};

export const userRegister = (payload) => {
  return {
    type: "register",
    payload
  }
};

export const userLogout = () => {
  return {
    type: "logout"
  }
};

export const userVerify = (payload) => {
  return {
    type: "verify",
    payload
  }
};


export const changeThemeMode = (payload) => {
  return {
    type: "setThemeMode",
    payload
  }
};
