import React from 'react';
import { useTranslation } from 'react-i18next';

function Account() {
  const {t} = useTranslation();

  return (
    <div>  
      <h2>{t("account")}</h2>
      
    </div>
  );
}

export default Account;
