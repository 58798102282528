import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Navbar, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import logo from '../../assets/images/logo.png';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { userLogout } from '../../store/actions';

const fr = require('../../assets/images/France.png');
const en = require('../../assets/images/US.png');
const nl = require('../../assets/images/Netherland.png');
const it = require('../../assets/images/Italy.png');
const hi = require('../../assets/images/India.png');



function Header(props) {
    
    const dispatch = useDispatch();
    const themeMode = useSelector((state) => state?.themeMode);
    const isLogged = useSelector((state) => state?.isLogged);
    const { i18n, t } = useTranslation();
    const location = useLocation();
    const [selectedLan, setSelectedLan] = useState(i18n?.language || 'en');

    const _logout = (e) => {
        e.preventDefault();
        document.body.classList.remove('light-theme');
        dispatch(userLogout());
    }


    const getImage = () => {

        if (selectedLan == 'en')
            return en
        if (selectedLan == 'nl')
            return nl
        if (selectedLan == 'it')
            return it
        if (selectedLan == 'fr')
            return fr
        if (selectedLan == 'hi')
            return hi
        return en

    }


    const changeLanguage = (lng) => {
        setSelectedLan(lng)
        i18n.changeLanguage(lng)
    }

    return (
        <header className="site-header">
            <Navbar fixed="top" color="light" expand="xs" className="border-bottom border-gray navbar">
                <Container>
                    <Row className="position-relative w-100 align-items-center">
                        <Col className="d-flex justify-content-start">
                            <NavLink to="/">
                                <img src={logo} alt="logo" className="position-relative img-fluid logo" />
                            </NavLink>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Dropdown> 
                                <Dropdown.Toggle  style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor: "#0d6efd" }} variant={'primary'}>
                                    <img src={getImage()} style={{ width: '1.65rem', height: '1.65rem' }} />
                                    <div style={{ marginLeft: "5px", fontSize: 16, fontWeight: "400", color: "#fff" }}>{selectedLan == 'fr' ? t("french") : selectedLan == 'nl' ? t("dutch") : selectedLan == 'it' ? t("italian") : selectedLan == 'hi' ? t("hindi") : t("english")}</div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu variant={themeMode}>
                                    <Dropdown.Item style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }} onClick={() => changeLanguage("en")}>
                                        <img src={en} style={{ width: 30, height: 30 }} />
                                        <div style={{ marginLeft: "10px", fontSize: 16, color: themeMode == "dark" ? "#fff" : "#000000" , fontWeight: "400" }}>{t("english")}</div>
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }} onClick={() => changeLanguage("fr")}>
                                        <img src={fr} style={{ width: 30, height: 30 }} />
                                        <div style={{ marginLeft: "10px", fontSize: 16, color: themeMode == "dark" ? "#fff" : "#000000" , fontWeight: "400" }}>{t("french")}</div>
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }} onClick={() => changeLanguage("hi")}>
                                        <img src={hi} style={{ width: 30, height: 30 }} />
                                        <div style={{ marginLeft: "10px", fontSize: 16, color: themeMode == "dark" ? "#fff" : "#000000" , fontWeight: "400" }}>{t("hindi")}</div>
                                    </Dropdown.Item> */}
                                    <Dropdown.Item style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }} onClick={() => changeLanguage("nl")}>
                                        <img src={nl} style={{ width: 30, height: 30 }} />
                                        <div style={{ marginLeft: "10px", fontSize: 16, color: themeMode == "dark" ? "#fff" : "#000000" , fontWeight: "400" }}>{t("dutch")}</div>
                                    </Dropdown.Item>
                                    <Dropdown.Item style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }} onClick={() => changeLanguage("it")}>
                                        <img src={it} style={{ width: 30, height: 30 }} />
                                        <div style={{ marginLeft: "10px", fontSize: 16, color: themeMode == "dark" ? "#fff" : "#000000" , fontWeight: "400" }}>{t("italian")}</div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {isLogged && <Button type="button" className="btn-logout" onClick={(e) => { _logout(e) }}>{t("logout")}</Button>}
                            {!isLogged && <div>
                                {location.pathname === '' || location.pathname === '/'
                                    ? (<NavLink to="/register"><Button type="button" className="btn-register" >{t("signup")}</Button></NavLink>)
                                    : (<NavLink to="/"><Button type="button" className="btn-login">{t("login")}</Button></NavLink>)
                                }
                            </div>}

                        </Col>

                    </Row>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
