import React, { useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeThemeMode } from '../../store/actions';

function Settings(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode);

  return (
    <div>  
      <h2>{t("settings")}</h2>

     {`${t("theme")}: `}&nbsp; <ToggleButtonGroup size="small" value={themeMode}
          exclusive className="theme-mode"
          onChange={(e, value)=>{  props.setThemeMode(value); }}
        >
          <ToggleButton value="light">{t("light")}</ToggleButton>
          <ToggleButton value="dark">{t("dark")}</ToggleButton>
        </ToggleButtonGroup>

    </div>
  );
}

export default Settings;
