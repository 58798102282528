import React from 'react';
import ReactApexChart from "react-apexcharts";


class StackedChart extends React.Component {

    constructor(props) {
        super(props);

        var data = props.data || {};
        var series = []; 
        var xaCategories = [];

        Object.keys(data).forEach(key => {
          
            var _itms = data[key] || {}
            Object.keys(_itms).forEach(_date => {
                if( _itms[_date] ){
                    var date = this.formateDate(_date);
                    if( !xaCategories.includes(date) )
                        xaCategories.push(date);
                }
            });

        });

        Object.keys(data).forEach(key => {
            var _data = [], itms = {}, _itms = data[key] || {};
          
            Object.keys(_itms).forEach(_date => {
                if( _itms[_date] ){
                    itms[this.formateDate(_date)] = _itms[_date];
                }
            });

            xaCategories.map((_date,i) =>{
                return _data.push(itms[_date] || 0);
            });
            
            if( _data.length ){  
                series.push({ name: key, data: _data });
            }  
        });

        window.Apex = {
            chart: {
                foreColor: props.themeMode === 'dark' ? '#ffffff' : '#222222',
                toolbar: { show: false },
            },
            tooltip:{ theme: props.themeMode },
            grid: { borderColor: props.themeMode === 'dark' ? "#888" : '#222222' }
        };  
 
        this.state = {
            series: series,
            options: {
                chart: { type:'bar', height:450, stacked:true, toolbar: { show:false }, zoom: { enabled:true }},
                responsive: [{ breakpoint:480, options:{ legend:{ position:'bottom', offsetX:-10, offsetY:0 }}}],
                plotOptions: { bar: { horizontal: false, borderRadius: 1 } },
                xaxis: {  categories: xaCategories },
                legend: { position: 'top', offsetY: 0 },
                fill: { opacity: 1 },
                tooltip: { theme: props.themeMode || "dark" },
            },
        };
    }

    formateDate = (date) =>{
        var  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var _dateA = date.split('-');
        return `${months[parseInt(_dateA[1])-1]} ${_dateA[2]}, ${_dateA[0]}`;
    }

    render() {    
        return (  
            <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
        );
    }
}

export default StackedChart;