import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from './components/Header';
import Footer from './components/Footer';

import Login from '../pages/Default/Login';
import Register from '../pages/Default/Register';
import Forgetpassword from '../pages/Default/Forgetpassword';
import Privacypolicy from '../pages/Default/Privacypolicy';
import CompleteProfile from '../pages/Default/CompleteProfile';

const DefaultLayout = (props) => {
        return (
            <Container>
                <Router>
                    <Header />
                    <main className="site-content">
                        <Container className="px-0">
                            <Row className="py-5 position-relative">
                                <Col tag="section" className="col-md-12">
                                    <Routes>
                                        <Route exact path="/" element={<Login />}/>
                                        <Route exact path="/complete-signup/:id/:hash" element={<CompleteProfile />}/>
                                        <Route exact path="/register" element={<Register />}/>
                                        <Route exact path="/forget-password" element={<Forgetpassword />}/>
                                        <Route exact path="/privacy-policy" element={<Privacypolicy />}/>
                                        <Route path="*" element={<Login />}/>
                                    </Routes>
                                </Col>    
                            </Row>
                        </Container>
                    </main>
                    <Footer />
                </Router>
            </Container>
        )
}

export default DefaultLayout;