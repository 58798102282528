import React,{ useEffect } from 'react';
import { useTranslation,} from 'react-i18next';
import { useSelector } from 'react-redux';

function Robots() {
  const {t} = useTranslation();

  return (
    <div>  
      <h2>{t("robots")}</h2>
    </div>
  );
}

export default Robots;

