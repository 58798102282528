import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import Api from "../../utils/Api";
import { useSelector } from 'react-redux';
import PieChart from '../../layout/components/PieChart';
import StackedChart from '../../layout/components/StackedChart';
import { useTranslation,} from 'react-i18next';


function Reports(){

    const {t} = useTranslation();
    const themeMode = useSelector(state => state?.themeMode);
    //const [chartData, setChartData] = useState({});
        
    // useEffect(() => {
        // Api.call('GET', 'https://17842811731f.ngrok.io/chart/' , null, { external:true } ).then(res=>{
        //     console.log("API_CALLLLLL", res);
        // });
    // },[]);
    
    const chartData = {"00003_datarobot": {"2022-06-30": 2, "2022-07-01": 2, "2022-07-02": 2, "2022-07-03": 2, "2022-07-04": 3, "2022-07-05": 6, "2022-07-06": 6, "2022-07-07": 12, "2022-07-08": 6, "2022-07-09": 6, "2022-07-10": 6, "2022-07-11": 12, "2022-07-12": 6, "2022-07-13": 6, "2022-07-14": 12}, "00012_hazelcast": {"2022-06-30": 2, "2022-07-01": 2, "2022-07-02": 2, "2022-07-03": 2, "2022-07-04": 10, "2022-07-05": 29, "2022-07-06": 37, "2022-07-07": 24, "2022-07-08": 24, "2022-07-09": 24, "2022-07-10": 24, "2022-07-11": 24, "2022-07-12": 24, "2022-07-13": 24, "2022-07-14": 19}, "00014_ngrok": {"2022-06-30": 2, "2022-07-01": 2, "2022-07-02": 2, "2022-07-03": 2, "2022-07-04": 2, "2022-07-05": 2, "2022-07-06": 2, "2022-07-07": 2, "2022-07-08": 2, "2022-07-09": 6, "2022-07-10": 2, "2022-07-11": 2, "2022-07-12": 2, "2022-07-13": 2, "2022-07-14": 2}, "00015_relicx": {"2022-06-30": 10, "2022-07-01": 12, "2022-07-02": 8, "2022-07-03": 8, "2022-07-04": 8, "2022-07-05": 8, "2022-07-06": 8, "2022-07-07": 8, "2022-07-08": 8, "2022-07-09": 8, "2022-07-10": 8, "2022-07-11": 8, "2022-07-12": 8, "2022-07-13": 8, "2022-07-14": 8}, "00016_ryan": {"2022-06-30": 3, "2022-07-01": 3, "2022-07-02": 3, "2022-07-03": 3, "2022-07-04": 4, "2022-07-05": 4, "2022-07-06": 4, "2022-07-07": 4, "2022-07-08": 4, "2022-07-09": 4, "2022-07-10": 4, "2022-07-11": 4, "2022-07-12": 4, "2022-07-13": 4, "2022-07-14": 4}, "00017_tora": {"2022-06-30": 1, "2022-07-01": 1, "2022-07-02": 1, "2022-07-03": 1, "2022-07-04": 2, "2022-07-05": 1, "2022-07-06": 1, "2022-07-07": 1, "2022-07-08": 1, "2022-07-09": 1, "2022-07-10": 1, "2022-07-11": 1, "2022-07-12": 1, "2022-07-13": 1, "2022-07-14": 1}};
    return (
        <div>  
            <h2>{t('reports')}</h2>
            <Row className="pt-2">    
                <Col md="12">
                    <StackedChart data={chartData} themeMode={themeMode}/>
                </Col>
            </Row>  
            <Row className="mt-3">
                <Col md="6">
                    <PieChart data={chartData}/>
                </Col>
                <Col md="6">
                    <SemiCircleProgressBar
                      diameter={400}
                      percentage={63}
                      showPercentValue={true}
                      stroke="blue"
                      background="#ccc"
                      strokeWidth={25}
                    />
                </Col>
                <Col md="6">

                </Col>
            </Row>
        </div>
    );
}

export default Reports;