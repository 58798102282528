import React, {useEffect, useState, Fragment} from 'react';
import { connect, useSelector, useDispatch } from "react-redux";
import Api from './utils/Api';
import { userLogin, userRegister, userVerify, userLogout, changeThemeMode } from "./store/actions";
import DefaultLayout from "./layout/DefaultLayout";
import DashboardLayout from "./layout/DashboardLayout";


import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.css';

function App(props){

  const dispatch = useDispatch();
  const isLogged = useSelector(state => state.isLogged);
  useEffect(() => {
    // if(isLogged){  
    
       dispatch(userVerify({}));
       
    // }
  },[]);
  
  return (
      <Fragment>
          {isLogged
              ? <DashboardLayout {...props}/>
              : <DefaultLayout {...props}/> 
          }
      </Fragment>
  );

}

const mapStateToProps = state => ({...state});
const mapDispatchToProps = dispatch => ({
  userLogin: (payload) => dispatch(userLogin(payload)),
  userLogout: (payload) => dispatch(userLogout(payload)),
  userRegister: (payload) => dispatch(userRegister(payload)),
  userVerify: (payload) => dispatch(userVerify(payload)),
  changeThemeMode: (payload) => dispatch(changeThemeMode(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);