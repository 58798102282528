import React, {useEffect, useState} from 'react';
import { NavLink } from "react-router-dom";
import { Row, Col, Form, FloatingLabel, Button, Spinner } from 'react-bootstrap';
import { Alert } from "@mui/material";
import footerLogo from '../../assets/images/logo-glow-black.png';
import Api from "../../utils/Api";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../store/actions';


function Login(props) {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const user = useSelector(state => state.userData);
    const [email, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);
    const [isProcessing, setProcessing] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async(e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();
        setError(false);

        if (form.checkValidity() !== false) {    
            setProcessing(true);

            Api.post(`login`,{email,password}).then(res => {
                setProcessing(false);
                if( res.success ){
                    dispatch(userLogin(res.data));
                    setValidated(false);
                } else{
                  
                    setError(res.error);
                }
            });
        }
        setValidated(true);
    }

    return (
        <div className="m-0 loginBox">

            <h2 className="heading m-0 mb-4">{t("loginDescription")}</h2>
            <p className="m-0 mb-1">{t("noAccount")}? <NavLink to="/register">{t("signupHere")}</NavLink>.</p>
            
            <Row className="justify-content-md-center">
                <Col md="5">
                    
                    <Form noValidate validated={validated} className="pt-5 mt-5" onSubmit={handleSubmit}>
            
                        {error && <Alert severity="error" className="mb-4">{error} !</Alert>}
            
                        <FloatingLabel label={t("emailAddress")} className="mb-3">
                            <Form.Control type="email" required placeholder={t("emailAddress")} className="formField" onChange={e => setUserEmail(e.target.value.trim())} />
                            </FloatingLabel>
                        <FloatingLabel label={t("password")}>
                            <Form.Control type="password" required placeholder={t("password")} className="formField" onChange={e => setPassword(e.target.value.trim())} />
                        </FloatingLabel>
                        
                        <div className="mt-4">
                            <Button type="submit" disabled={isProcessing}>
                                {isProcessing && <Spinner className="mx-3" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>}
                                {!isProcessing && <span>{t("login")}</span>} 
                            </Button>
                        </div>    
                    </Form>

                    <div className="mt-5">
                        {t("forgetPassword")}? <NavLink to="/forget-password">{t("resetHere")}.</NavLink>
                    </div>  
                </Col>
                <Col md="7">
                    <div className="ms-4">
                        <img src={footerLogo} alt="Grow logo" className="position-relative img-fluid footer-logo" />
                    </div>
                </Col>
            </Row>
        </div>
    );

}

export default Login;
