import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import Header from './components/Header';
import Footer from './components/Footer';

import Dashboard from '../pages/Dashboard/Dashboard';
import Robots from '../pages/Dashboard/Robots';
import Reports from '../pages/Dashboard/Reports';
import Team from '../pages/Dashboard/Team';
import Billing from '../pages/Dashboard/Billing';
import Support from '../pages/Dashboard/Support';
import Settings from '../pages/Dashboard/Settings';
import Account from '../pages/Dashboard/Account';
import { withTranslation } from 'react-i18next';

const  Icon = ({name}) =>{
    const [icon, setIcon] = useState(null);
    useEffect(() => {
        import("../assets/images/icons/"+name+".png").then(mod => {
            setIcon(mod.default);
        });
    },[]);

    return (
        <span className="navicon">{icon && <img src={icon} alt={name} className="navicon"/>}</span>
    );
}

class DashboardLayout extends React.Component {
    
    navigation = [];
    navigationUser = [];

    constructor(props) {
        super(props);
        this.state = {
            userData: this.props.userData,
        }
    }

    initNavigation = () => {
        const {t} = this.props;
        this.navigation = [
            {
                name: t("myRobots"),
                path: "robots",
                icon: "robots",
                component: <Robots {...this.props}/>
            },
            {
                name: t("reports"),
                path: "reports",
                icon: "reports",
                component: <Reports {...this.props}/>
            },
            {
                name: t("team"),
                path: "team",
                icon: "team",
                component: <Team {...this.props}/>
            },
            {
                name: t("billing"),
                path: "billing",
                icon: "billing",
                component: <Billing {...this.props}/>
            }
        ];  

        var user = this.state.userData || {};
        var permissions = user?.permissions || [];
        this.navigation = this.navigation.filter((nav)=>{ 
            return 1 || user?.user_type === 'A' || permissions.includes(nav.path); 
        });

        this.navigationUser = [
            {
                name: t("support"),
                path: "support",
                icon: "support",
                component: <Support {...this.props}/>
            },
            {
                name: t("settings"),
                path: "settings",
                icon: "settings",
                component: <Settings {...this.props} setThemeMode={this._setThemeMode}/>
            },
            {
                name: t("myAccount"),
                path: "my-account",
                icon: "account",
                component: <Account {...this.props}/>
            }
        ];                                          
    }

    componentDidMount() {
        var mode = false;
        if( (mode = localStorage.getItem('themeMode')) ){
            this.props.changeThemeMode(mode);
            this._changeThemeMode(mode);
        }
    }

    _setThemeMode = (mode) =>{
        this.props.changeThemeMode(mode);
        this._changeThemeMode(mode);
        
    }

    _changeThemeMode = (mode) =>{
        document.body.classList.remove('light-theme');
        if( mode === 'light')
            document.body.classList.add('light-theme');
    }

    _navigation = () =>{
        return (
            <ul className="dashboard-navigation">
                {this.navigation.map((nav)=>{
                   return (<li key={nav.path}>
                        <NavLink to={nav.path}><Icon name={nav.icon}/> {nav.name}</NavLink>
                    </li>); 
                })}
                {this.navigationUser.map((nav)=>{
                   return (<li key={nav.path}>
                        <NavLink to={nav.path}><Icon name={nav.icon}/> {nav.name}</NavLink>
                    </li>); 
                })}
            </ul>
        );
    }

    render() {
        this.initNavigation();

        return (
            <Container>
                <Router>
                    <Header {...this.props}/>
                    <main className="site-content">
                        <Container className="px-0">
                            <Row className="py-5 position-relative">
                                <Col md={{ size: 4 }} tag="aside" className="pb-2 col-sm-12 col-lg-3 col-md-4">
                                    {this._navigation()} 
                                </Col>    
                                <Col md={{ size: 8 }} tag="section" className="col-sx-12 col-sm-12 col-lg-9 col-md-8">
                                    <div className="dashboard-content">
                                        <Routes>
                                            <Route exact path="/" element={<Dashboard {...this.props}/>}/>
                                            {this.navigation.map((nav)=>{
                                               return <Route key={nav.path} path={nav.path} element={nav.component}/>; 
                                            })}
                                            {this.navigationUser.map((nav)=>{
                                               return <Route key={nav.path} path={nav.path} element={nav.component}/>; 
                                            })}
                                            <Route path="*" element={<Dashboard {...this.props}/>}/>
                                        </Routes>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </main>
                    <Footer {...this.props}/>
                </Router>
            </Container>
        );
    }
}

export default withTranslation() (DashboardLayout);
